import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TimeTrackingDay } from '../GraphQL/indexV2';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '../FeatureFlagEnums';
import { countOnCallHours } from './onCallTimeHelpers';

interface Props {
	data: TimeTrackingDay;
}

const OnCallSummarizer: React.FC<Props> = ({ data }) => {
	const { t } = useTranslation();

	const showOnCallHours = useFlag(FeatureFlagEnums.SHOW_ONCALL_HOURS);
	const onCallSummarize = useFlag(FeatureFlagEnums.ON_CALL_SUMMARIZE);

	if(data === undefined) return <></>;

	const currentDay = data;

	const destructuredEntries = currentDay?.timeTrackingEntries.map(({ jobNo, jobTaskNo, hours, onCall, startTime, endTime, startDate }) => ({
		jobNo,
		jobTaskNo,
		hours,
		onCall,
		startTime,
		endTime,
		startDate,
	})) ?? [];

	const destructuredAddonLines = currentDay?.timeTrackingEntries.flatMap(e => 
		e.addonLines.map(({ paymentSupplementCode, jobNo, jobTaskNo, hours, onCall }) => ({
			paymentSupplementCode,
			jobNo,
			jobTaskNo,
			hours,
			onCall,
			startTime: e.startTime,
			endTime: e.endTime,
		})),
	) ?? [];

	const onCallHours = countOnCallHours(destructuredEntries, destructuredAddonLines, '0601', 2.5);
	const onCallbackHours = countOnCallHours(destructuredEntries, destructuredAddonLines, '0600', 3);

	return (
		<div>
			{onCallSummarize
				? (
					<div className="flex flex-col justify-end items-end">
						{onCallHours.hours > 0 && (
							<p className="ml-2 font-semibold">
								<Trans
									t={t}
									i18nKey="timeRegistration.onCallHourSummary"
									count={onCallHours.hours}
									values={{
										onCallHours: onCallHours.hours,
									}}
								/>
								{onCallHours.overtimeHours > 0 &&
									<span className="ml-2 font-semibold">
										(<Trans
											t={t}
											i18nKey="timeRegistration.onCallOvertimeHourSummary"
											count={onCallHours.overtimeHours}
											values={{
												onCallHours: onCallHours.overtimeHours,
											}}
										/>)
									</span>
								}
								{onCallHours.eveningHours > 0 &&
									<span className="ml-2 font-semibold">
										(<Trans
											t={t}
											i18nKey="timeRegistration.onCallEveningHourSummary"
											count={onCallHours.eveningHours}
											values={{
												onCallHours: onCallHours.eveningHours,
											}}
										/>)
									</span>
								}
								{onCallHours.nightHours > 0 &&
									<span className="ml-2 font-semibold">
										(<Trans
											t={t}
											i18nKey="timeRegistration.onCallNightHourSummary"
											count={onCallHours.nightHours}
											values={{
												onCallHours: onCallHours.nightHours,
											}}
										/>)
									</span>
								}
								{onCallHours.sundayHours > 0 &&
									<span className="ml-2 font-semibold">
										(<Trans
											t={t}
											i18nKey="timeRegistration.onCallSundayHourSummary"
											count={onCallHours.sundayHours}
											values={{
												onCallHours: onCallHours.sundayHours,
											}}
										/>)
									</span>
								}
							</p>
						)}
						{onCallbackHours.hours > 0 && (
							<p className="ml-2 font-semibold">
								<Trans
									t={t}
									i18nKey="timeRegistration.onCallbackHourSummary"
									count={onCallbackHours.hours}
									values={{
										onCallbackHours: onCallbackHours.hours,
									}}
								/>
								{onCallbackHours.overtimeHours > 0 && 
									<span className="ml-2">
										(<Trans
											t={t}
											i18nKey="timeRegistration.onCallbackOvertimeHourSummary"
											count={onCallbackHours.overtimeHours}
											values={{
												onCallbackHours: onCallbackHours.overtimeHours,
											}}
										/>)
									</span>
								}
								{onCallHours.eveningHours > 0 &&
									<span className="ml-2 font-semibold">
										(<Trans
											t={t}
											i18nKey="timeRegistration.onCallEveningHourSummary"
											count={onCallHours.eveningHours}
											values={{
												onCallbackHours: onCallHours.eveningHours,
											}}
										/>)
									</span>
								}
								{onCallHours.nightHours > 0 &&
									<span className="ml-2 font-semibold">
										(<Trans
											t={t}
											i18nKey="timeRegistration.onCallNightHourSummary"
											count={onCallHours.nightHours}
											values={{
												onCallbackHours: onCallHours.nightHours,
											}}
										/>)
									</span>
								}
								{onCallHours.sundayHours > 0 &&
									<span className="ml-2 font-semibold">
										(<Trans
											t={t}
											i18nKey="timeRegistration.onCallSundayHourSummary"
											count={onCallHours.sundayHours}
											values={{
												onCallbackHours: onCallHours.sundayHours,
											}}
										/>)
									</span>
								}
							</p>
						)}
					</div>
				) : (
					<>
					{showOnCallHours &&
						<div className="flex flex-col justify-end items-end">
							{onCallHours.hours > 0 && (
								<p className="ml-2 font-semibold">
									<Trans
										t={t}
										i18nKey="timeRegistration.onCallHourSummary"
										count={onCallHours.hours}
										values={{
											onCallHours: onCallHours.hours,
										}}
									/>
									{onCallHours.overtimeHours > 0 &&
										<span className="ml-2 font-semibold">
											(<Trans
												t={t}
												i18nKey="timeRegistration.onCallOvertimeHourSummary"
												count={onCallHours.overtimeHours}
												values={{
													onCallOvertimeHours: onCallHours.overtimeHours,
												}}
											/>)
										</span>
									}
								</p>
							)}
							{onCallbackHours.hours > 0 && (
								<p className="ml-2 font-semibold">
									<Trans
										t={t}
										i18nKey="timeRegistration.onCallbackHourSummary"
										count={onCallbackHours.hours}
										values={{
											onCallbackHours: onCallbackHours.hours,
										}}
									/>
									{onCallbackHours.overtimeHours > 0 && 
										<span className="ml-2">
											(<Trans
												t={t}
												i18nKey="timeRegistration.onCallbackOvertimeHourSummary"
												count={onCallbackHours.overtimeHours}
												values={{
													onCallbackOvertimeHours: onCallbackHours.overtimeHours,
												}}
											/>)
										</span>
									}
								</p>
							)}
						</div>
					}
					</>
				)
			}
		</div>
	);
};

export default OnCallSummarizer;
