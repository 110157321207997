import React from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { AirMeassurementDetailsInput, FloorMoistureTrygReportFormDataInput, ReportFileInput, ESDHFileFragment, ReportFormatType, GetMobileV2DrivingSlip_drivingSlip_case } from 'GQL';
import { fileToInput } from '@ssg/common/Helpers/fileTypeHelper';
import { toggleArrayValue } from '@ssg/common/Helpers/toggleArrayValue';
import { useHistory } from 'react-router-dom';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import { removeTypenameGeneric } from '@ssg/common/Helpers/typenameHelper';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import useDrivingSlipImages from './useDrivingSlipImages';
import BasePage from 'Components/Layout/BasePage';
import AirMeassurementDetailsForm from './Inputs/AirMeassurementDetailsForm';
import DrivingSlipMultiImageSelect from './Inputs/DrivingSlipMultiImageSelect';
import DrivingSlipSingleImageSelect from './Inputs/DrivingSlipSingleImageSelect';
import TextAreaInput from './Inputs/TextAreaInput';
import ReportStaticInfo from './ReportStaticInfo';
import Button from 'Components/Button';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import TrygMoistureMeassurementForm from './Inputs/TrygMoistureMeassurementForm';
import TextButton from '@ssg/common/Components/TextButton';
import TextInput from './Inputs/TextInput';
import { checkFilesExist, tryGetFile } from './checkFilesExist';
import { updateReportsCache, updateReportsCacheNew } from './updateReportsCache';
import Datepicker from '@ssg/common/Components/Datepicker';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { ApiMutationKey, useCreateMoistureTrygReport } from 'Store';
import dateToReportNameString from 'Helpers/dateHelper';
import { isConnected } from 'NetworkContext';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';

interface Props {
	drivingSlipId: string;
	drivingSlipCase: GetMobileV2DrivingSlip_drivingSlip_case;
}

interface ReportError {
	key: keyof FloorMoistureTrygReportFormDataInput;
	index: number;
}

const EMPTY_AIR_MEASSURMENT = (): AirMeassurementDetailsInput => ({
	temperatureInside: 0,
	temperatureOutside: 0,
	relativeHumidityInside: 0,
	relativeHumidityOutside: 0,
	waterAmountInside: 0,
	waterAmountOutside: 0,
});
const tempImage: ReportFileInput = {
	fileName: '',
	description: '',
};

const EMPTY_ROW = (damageCause = '', multipleDamageCauses = '', moistureDamageCause = ''): FloorMoistureTrygReportFormDataInput => ({
	name: '',
	floorplanImage: tempImage,
	damageCause,
	damageDescription: '',
	damageDescriptionImage: [],
	damageDrawingImage: tempImage,
	moistureDamageCause,
	moistureMeassurements: [],
	multipleDamageCauses,
	otherImages: [],
	recommendations: undefined,
	urgentActions: undefined,
	airMeassurements: EMPTY_AIR_MEASSURMENT(),
});

const TrygWaterDamageReport: React.FC<Props> = ({ drivingSlipId, drivingSlipCase }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const saveButtonReportsFlag = useFlag(FeatureFlagEnums.SAVE_BUTTON_REPORTS);
	const mobileFacadeImageSelectFlag = useFlag(FeatureFlagEnums.MOBILE_FACADE_IMAGE_SELECT);
	const optionalRoomFloorInReports = useFlag(FeatureFlagEnums.OPTIONAL_ROOM_FLOOR_IN_REPORTS);

	const { images } = useDrivingSlipImages(drivingSlipCase.erpNo, drivingSlipId);

	const useLocalStorage = <T,>(name: string, initialValue: T) => useStorageState<T>(window.localStorage, `${drivingSlipCase.id}-trygwater-${name}`, initialValue);

	const [floors, setFloors] = useLocalStorage<FloorMoistureTrygReportFormDataInput[]>('floors', [EMPTY_ROW()]);

	const [facadeImage, setFacadeImage] = useLocalStorage<ESDHFileFragment | null>('facadeImage', null);
	const [inspectionDate, setInspectionDate] = useLocalStorage('inspectionDate-indoorClimate', formatDateForInput(new Date()));
	const isSetRef = React.useRef(false);
	React.useEffect(() => {
		const formData = removeTypenameGeneric(drivingSlipCase.caseReports?.moistureTrygReportFormData);
		if (formData && !isSetRef.current) {
			if (formData.facadeImage) {
				const sanitizedFacadeImage = checkFilesExist([formData.facadeImage], images)[0];
				if (sanitizedFacadeImage) {
					setFacadeImage(tryGetFile(sanitizedFacadeImage.fileName, images));
				}
			}
			if (formData.inspectionDate) setInspectionDate(formatDateForInput(new Date(formData.inspectionDate)));

			if (formData.floorReport) {
				const floorsData = removeTypenameGeneric(formData.floorReport);
				if (floorsData) setFloors(floorsData);
			}

			if (formData.floorReport) {
				const floorsData = removeTypenameGeneric(formData.floorReport);
				const sanitizedFloorsData = floorsData.map(f => {
					return {
						...f,
						damageDescriptionImage: checkFilesExist(f.damageDescriptionImage ?? [], images),
						damageDrawingImage: checkFilesExist(f.damageDrawingImage ? [f.damageDrawingImage] : [], images)[0],
						otherImages: checkFilesExist(f.otherImages ?? [], images),
						floorplanImage: checkFilesExist(f.floorplanImage ? [f.floorplanImage] : [], images)[0],
					};
				});
				if (sanitizedFloorsData) setFloors(sanitizedFloorsData);
			}
		}
		isSetRef.current = true;
	}, [drivingSlipCase.caseReports?.moistureTrygReportFormData, images, setFacadeImage, setFloors, setInspectionDate]);
	const [activeTab, setActiveTab] = React.useState<number>(0);

	const [submitted, setSubmitted] = React.useState(false);
	const [saved, setSaved] = React.useState(false);
	const [loadingType, setLoadingType] = React.useState<'CREATE' | 'SAVE'>('CREATE');
	const [reportErrors, setReportErrors] = React.useState<ReportError[]>([]);
	const [facadeImageError, setFacadeImageError] = React.useState(false);
	const reportFormat = React.useRef<ReportFormatType>(ReportFormatType.PDF);

	const [showChooseFloorModal, setShowChooseFloorModal] = React.useState(false);
	const [selectedFloors, setSelectedFloors] = React.useState<string[]>([]);

	// Setup mutation to create water damage report
	const [createMoistureTrygReport, { loading: submitting }] = useCreateMoistureTrygReport();

	const getDescription = (desciptionMap: ReportFileInput[], fileName: string) => {
		return desciptionMap.find(dm => dm.fileName === fileName);
	};

	async function submitReport(reportFormatType: ReportFormatType) {
		reportFormat.current = reportFormatType;

		setSubmitted(true);
		if (facadeImage == null) {
			setFacadeImageError(true);
		}

		const errors = errorCheck(floors);
		setReportErrors(errors);

		if (errors.length > 0 || facadeImage == null) {
			return;
		}

		const descriptionMap: ReportFileInput[] = images.map(image => ({
			fileName: image.name,
			description: image.comments ?? '',
		}));
		const thisFacadeImage = images.find(image => image.name === facadeImage.name) ?? facadeImage;

		const theseFloors = floors.slice();
		theseFloors.forEach((floor, i) => {
			theseFloors[i].floorplanImage = getDescription(descriptionMap, theseFloors[i].floorplanImage!.fileName) ?? theseFloors[i].floorplanImage;
			theseFloors[i].damageDescriptionImage = floor.damageDescriptionImage?.map(oi => getDescription(descriptionMap, oi.fileName) ?? oi);
			theseFloors[i].damageDrawingImage = getDescription(descriptionMap, theseFloors[i].damageDrawingImage!.fileName) ?? theseFloors[i].damageDrawingImage;
			theseFloors[i].otherImages = floor.otherImages?.map(oi => getDescription(descriptionMap, oi.fileName) ?? oi);
		});

		try {
			setLoadingType('CREATE');
			await createMoistureTrygReport({
				variables: {
					caseId: drivingSlipCase.id,
					formData: {
						inspectionDate: formatDateForInput(new Date(inspectionDate)),
						facadeImage: fileToInput(thisFacadeImage),
						floorMoistureTrygReportData: theseFloors,
					},
					onlySave: false,
					floorsToInclude: selectedFloors.length > 0 ? selectedFloors : null,
					reportFormat: reportFormat.current,
				},
				onOptimisticResponseOverride: cache => {
					const created = new Date().toISOString();
					const createdSplit = dateToReportNameString(created);
					const extension = reportFormat.current === ReportFormatType.PDF ? 'pdf' : 'docx';
					const report = {
						name: `Tryg fugtrapport_${drivingSlipCase.erpNo}_${createdSplit}.${extension} (Oprettet Offline, kan ikke tilgås endnu)`,
						comments: null,
						created: created,
						extension: extension,
						fileId: created,
						groupingKey: '',
						metadata: [{ key: 'DokumentType', value: 'Rapport' }],
						thumbnail: '',
						url: 'report.' + extension,
						__typename: 'ESDHFile',
					};
					if (!isConnected()) {
						updateReportsCacheNew(drivingSlipCase.erpNo, report, cache);
					}
					// const cachedRequest = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
					// 	query: GET_CASE_FILES,
					// 	variables: {
					// 		caseErpNo: drivingSlipCase.erpNo,
					// 		folder: drivingSlipCase.erpNo,
					// 	},
					// });
					// console.log(cachedRequest);
					// console.log([report, ...(cachedRequest?.caseFiles ?? [])]);
					// cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
					// 	query: GET_CASE_FILES,
					// 	variables: {
					// 		caseErpNo: drivingSlipCase.erpNo,
					// 		folder: drivingSlipCase.erpNo,
					// 	},
					// 	data: {
					// 		caseFiles: [report, ...(cachedRequest?.caseFiles ?? [])],
					// 	},
					// });
				},
				update: updateReportsCache(drivingSlipCase.erpNo, result => result.createMoistureTrygReport),
				queueOfflineMutationOptions: {
					key: ApiMutationKey.DrivingSlip_CreateMoistureTrygReport,
					removePreviousEntry: true,
					findPreviousEntryPredicate(variables) {
						return variables.caseId === drivingSlipCase.id;
					},
				},
			});
			history.goBack();
		} catch (e) {
			console.error(e);
		}
	}

	React.useEffect(() => {
		setSaved(false);
	}, [inspectionDate,
		facadeImage,
		floors]);

	async function saveReport() {
		const descriptionMap: ReportFileInput[] = images.map(image => ({
			fileName: image.name,
			description: image.comments,
		}));
		const thisFacadeImage = images.find(image => image.name === facadeImage?.name) ?? facadeImage;

		const theseFloors = floors.slice();
		theseFloors.forEach((floor, i) => {
			theseFloors[i].floorplanImage = getDescription(descriptionMap, theseFloors[i].floorplanImage?.fileName ?? '') ?? theseFloors[i].floorplanImage;
			theseFloors[i].damageDescriptionImage = floor.damageDescriptionImage?.map(oi => getDescription(descriptionMap, oi.fileName) ?? oi);
			theseFloors[i].damageDrawingImage = getDescription(descriptionMap, theseFloors[i].damageDrawingImage?.fileName ?? '') ?? theseFloors[i].damageDrawingImage;
			theseFloors[i].otherImages = floor.otherImages?.map(oi => getDescription(descriptionMap, oi.fileName) ?? oi);
		});

		try {
			setLoadingType('SAVE');
			await createMoistureTrygReport({
				variables: {
					caseId: drivingSlipCase.id,
					formData: {
						inspectionDate: formatDateForInput(new Date(inspectionDate)),
						facadeImage: thisFacadeImage ? fileToInput(thisFacadeImage) : null,
						floorMoistureTrygReportData: theseFloors,
					},
					reportFormat: reportFormat.current,
					onlySave: true,
				},
				queueOfflineMutationOptions: {
					key: ApiMutationKey.DrivingSlip_CreateMoistureTrygReport,
					removePreviousEntry: true,
					findPreviousEntryPredicate(variables) {
						return variables.caseId === drivingSlipCase.id;
					},
				},
			});
			setSaved(true);
		} catch (e) {
			console.error(e);
		}
	}

	const handleChange = (idx: number, column: keyof FloorMoistureTrygReportFormDataInput, value: any) =>
		setFloors(current => {
			let originalValue = value;
			if (column === 'floorplanImage') {
				originalValue = fileToInput(value as ESDHFileFragment);
			}

			if (column === 'damageDescriptionImage') {
				const fileValue = value as ESDHFileFragment;
				const imageArrToggles = toggleArrayValue(current[idx].damageDescriptionImage || [], { fileName: fileValue.name }, img => img.fileName);

				originalValue = imageArrToggles;
			}

			if (column === 'damageDrawingImage') {
				originalValue = fileToInput(value as ESDHFileFragment);
			}

			if (column === 'otherImages') {
				const fileValue = value as ESDHFileFragment;
				const imageArrToggles = toggleArrayValue(current[idx].otherImages || [], { fileName: fileValue.name }, img => img.fileName);

				originalValue = imageArrToggles;
			}

			return [
				...current.slice(0, idx),
				{
					...current[idx],
					[column]: originalValue,
				},
				...current.slice(idx + 1),
			];
		});

	function addRow() {
		setFloors(current => [...current, EMPTY_ROW(current[0].damageCause ?? '', current[0].multipleDamageCauses ?? '', current[0].moistureDamageCause ?? '')]);
		setActiveTab(floors.length);
	}

	function removeRow(idx: number) {
		if (window.confirm(t('reports.confirmRemoveFloor'))) {
			setFloors(floors.filter((_, i) => i !== idx));
			setActiveTab(0);
		}
	}

	React.useEffect(() => {
		if (submitted) {
			setFacadeImageError(facadeImage == null);
			const errors = errorCheck(floors);
			setReportErrors(errors);
		}
	}, [facadeImage, floors, submitted]);

	const errorCheck = (flrs: FloorMoistureTrygReportFormDataInput[]) => {
		const errors: ReportError[] = [];
		flrs.forEach((f, i) => {
			if (!f.damageCause || f.damageCause.length < 1) {
				errors.push({ index: i, key: 'damageCause' });
			}
			if (!f.moistureDamageCause || f.moistureDamageCause.length < 1) {
				errors.push({ index: i, key: 'moistureDamageCause' });
			}
			if (!f.damageDescription || f.damageDescription.length < 1) {
				errors.push({ index: i, key: 'damageDescription' });
			}
			if (!f.floorplanImage || f.floorplanImage?.fileName.length < 1) {
				errors.push({ index: i, key: 'floorplanImage' });
			}
			if (f.airMeassurements.relativeHumidityInside === 0 && f.airMeassurements.temperatureInside === 0) {
				errors.push({ index: i, key: 'airMeassurements' });
			}
			if (!f.damageDescriptionImage || f.damageDescriptionImage.length < 1) {
				errors.push({ index: i, key: 'damageDescriptionImage' });
			}
			if (!f.damageDrawingImage || f.damageDrawingImage?.fileName.length < 1) {
				errors.push({ index: i, key: 'damageDrawingImage' });
			}
		});

		return errors;
	};

	const resetForm = () => {
		setFacadeImage(null);
		setFloors([EMPTY_ROW()]);
	};

	const throwErrorMessage = (index: number, key: keyof FloorMoistureTrygReportFormDataInput): string | undefined => {
		const isError = reportErrors.filter(re => re.index === index && re.key === key).length > 0;
		if (isError) return 'error.required';
		return;
	};

	React.useEffect(() => {
		setSelectedFloors(floors.map(f => f.name).filter((name): name is string => name !== undefined));
	}, [floors]);

	return (
		<BasePage title={t('reports.reportTypes.trygWater')}>
			<IonRow>
				<ReportStaticInfo drivingSlipCase={drivingSlipCase} resetForm={() => resetForm()} />

				{mobileFacadeImageSelectFlag ? (
					<DrivingSlipMultiImageSelect
						label={t('reports.uploadFacadeImage')}
						caseNo={drivingSlipCase.erpNo}
						drivingSlipId={drivingSlipId}
						onImageSelect={setFacadeImage}
						selectedImages={facadeImage ? [facadeImage] : []}
					/>
				) : (
					<DrivingSlipSingleImageSelect
						caseNo={drivingSlipCase.erpNo}
						drivingSlipId={drivingSlipId}
						noImageText={t('reports.noFacadeImage')}
						uploadButtonText={t('reports.uploadFacadeImage')}
						onImageSelect={setFacadeImage}
						selectedImage={facadeImage}
					/>
				)}
				{facadeImageError && <FormErrorText text="reports.error.facadeImage" />}
				<Datepicker title="reports.inspectionDate" value={inspectionDate} onChange={e => setInspectionDate(e.target.value)} name="reports.inspectionDate" className="mx-1" titleClass="mx-1" />
				<IonCol size="12" className="flex flex-wrap">
					<div className="w-full">
						<ul className="mb-0 flex list-none flex-row flex-wrap space-y-2 pt-3 pb-4" role="tablist">
							{floors.map((floor, idx) => (
								<li key={idx} className="mr-2 -mb-px w-full flex-auto text-center last:mr-0 sm:w-auto">
									{idx === 0}{' '}
									<a
										className={
											'block rounded px-5 py-3 text-xs font-bold uppercase leading-normal shadow-lg ' +
											(activeTab === idx ? 'bg-blue-calm text-white' : 'text-blue-calm bg-white')
										}
										onClick={e => {
											e.preventDefault();
											setActiveTab(idx);
										}}
										data-toggle="tab"
										href={'#link' + idx}
										role="tablist"
									>
										{typeof reportErrors.find(re => re.index === idx) !== 'undefined' && <FontAwesomeIcon icon={faExclamationTriangle} className="text-red mr-1" size="lg" />}
										{t('reports.floor')} - {floor.name}
									</a>
								</li>
							))}

							<li className="mr-2 -mb-px flex-auto text-center last:mr-0">
								<Button onClick={addRow}>{t('reports.addFloor')}</Button>
							</li>
						</ul>
						<div className="relative mb-6 w-full break-words rounded bg-white shadow-lg">
							<div className="flex-auto py-5">
								<div className="tab-content tab-space">
									{floors.map((floor, idx) => (
										<div key={'#link' + idx} className={activeTab === idx ? 'block' : 'hidden'} id={'#link' + idx}>
											<IonGrid className="mb-4 px-0 text-sm">
												{idx > 0 && <TextButton text="reports.removeFloor" className="ml-auto mr-0" icon={faTimes} iconClassName="text-red" onClick={() => removeRow(idx)} />}
												<IonRow>
													<TextInput
														label={t('reports.name')}
														value={floor.name}
														onChange={value => handleChange(idx, 'name', value)}
														errorMessage={throwErrorMessage(idx, 'name')}
													/>
													<TextAreaInput
														label={t('reports.damageCause')}
														placeholder={t('reports.damageCausePlaceholder')}
														value={floor.damageCause ?? undefined}
														onChange={value => handleChange(idx, 'damageCause', value)}
														errorMessage={throwErrorMessage(idx, 'damageCause')}
														required
													/>

													<TextAreaInput
														label={t('reports.multipleDamageCauses')}
														placeholder={t('reports.multipleDamageCausesPlaceholder')}
														value={floor.multipleDamageCauses ?? undefined}
														onChange={value => handleChange(idx, 'multipleDamageCauses', value)}
													/>

													<TextAreaInput
														label={t('reports.moistureDamageCause')}
														placeholder={t('reports.moistureDamageCausePlaceholder')}
														value={floor.moistureDamageCause ?? undefined}
														onChange={value => handleChange(idx, 'moistureDamageCause', value)}
														required
														errorMessage={throwErrorMessage(idx, 'moistureDamageCause')}
													/>

													<TextAreaInput
														label={t('reports.damageDescription')}
														placeholder={t('reports.damageDescriptionPlaceholder')}
														value={floor.damageDescription ?? undefined}
														onChange={value => handleChange(idx, 'damageDescription', value)}
														required
														errorMessage={throwErrorMessage(idx, 'damageDescription')}
													/>

													<DrivingSlipSingleImageSelect
														caseNo={drivingSlipCase.erpNo}
														drivingSlipId={drivingSlipId}
														noImageText={t('reports.noFloorplanImage')}
														uploadButtonText={t('reports.uploadFloorplanImage')}
														onImageSelect={e => handleChange(idx, 'floorplanImage', e)}
														//selectedImage={floorplanIndexedImage?.get(idx)}
														selectedImage={images.filter(img => img.name === floor.floorplanImage?.fileName)[0] ?? undefined}
													/>
													{typeof throwErrorMessage(idx, 'floorplanImage') !== 'undefined' && <FormErrorText text="reports.error.floorplanImage" />}

													{/* <MoistureMeassurementForm meassurements={moistureMeassurements} onChange={setMoistureMeassurements} /> */}
													<TrygMoistureMeassurementForm meassurements={floor.moistureMeassurements ?? []} onChange={e => handleChange(idx, 'moistureMeassurements', e)} />

													<AirMeassurementDetailsForm meassurements={floor.airMeassurements} onChange={e => handleChange(idx, 'airMeassurements', e)} />
													{typeof throwErrorMessage(idx, 'airMeassurements') !== 'undefined' && <FormErrorText text="reports.error.airMeassurements" />}

													<DrivingSlipMultiImageSelect
														caseNo={drivingSlipCase.erpNo}
														drivingSlipId={drivingSlipId}
														label={t('reports.uploadDamageDescriptionImage')}
														// noImageText={t('reports.noDamageDescriptionImage')}
														// uploadButtonText={t('reports.uploadDamageDescriptionImage')}
														selectedImages={images.filter(img => (floor.damageDescriptionImage ?? []).map(oi => oi.fileName).includes(img.name))}
														onImageSelect={image => handleChange(idx, 'damageDescriptionImage', image)}
													/>
													{typeof throwErrorMessage(idx, 'damageDescriptionImage') !== 'undefined' && <FormErrorText text="reports.error.damageDescriptionImage" />}

													<DrivingSlipSingleImageSelect
														caseNo={drivingSlipCase.erpNo}
														drivingSlipId={drivingSlipId}
														noImageText={t('reports.noDamageDrawingImage')}
														uploadButtonText={t('reports.uploadDamageDrawingImage')}
														onImageSelect={e => handleChange(idx, 'damageDrawingImage', e)}
														selectedImage={images.filter(img => img.name === floor.damageDrawingImage?.fileName)[0] ?? undefined}
													/>
													{typeof throwErrorMessage(idx, 'damageDrawingImage') !== 'undefined' && <FormErrorText text="reports.error.damageDrawingImage" />}

													<TextAreaInput
														label={t('reports.recommendations')}
														placeholder={t('reports.recommendationsPlaceholder')}
														value={floor.recommendations ?? undefined}
														onChange={value => handleChange(idx, 'recommendations', value)}
													/>

													<TextAreaInput
														label={t('reports.urgentActions')}
														placeholder={t('reports.urgentActionsPlaceholder')}
														value={floor.urgentActions ?? undefined}
														onChange={value => handleChange(idx, 'urgentActions', value)}
													/>

													<DrivingSlipMultiImageSelect
														caseNo={drivingSlipCase.erpNo}
														label={t('reports.images')}
														drivingSlipId={drivingSlipId}
														onImageSelect={image => handleChange(idx, 'otherImages', image)}
														selectedImages={images.filter(img => (floor.otherImages || []).map(oi => oi.fileName).includes(img.name))}
													/>
												</IonRow>

												{idx > 0 && (
													<p className="text-red pt-2 text-right text-xl font-bold" onClick={() => removeRow(idx)}>
														x <span className="text-sm">Remove Floor</span>
													</p>
												)}
											</IonGrid>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</IonCol>
				<IonCol size="12">
					{optionalRoomFloorInReports
						? <Button expand="block" onClick={() => setShowChooseFloorModal(true)}>Generer rapport</Button>
						: (
							<>
								<Button id="PDF" expand="block" disabled={submitting} loading={submitting && reportFormat.current === ReportFormatType.PDF && loadingType === 'CREATE'} onClick={() => submitReport(ReportFormatType.PDF)}>
									{t('reports.createPdfReport')}
								</Button>

								<Button id="WORD" expand="block" disabled={submitting} loading={submitting && reportFormat.current === ReportFormatType.WORD && loadingType === 'CREATE'} onClick={() => submitReport(ReportFormatType.WORD)}>
									{t('reports.createWordReport')}
								</Button>
							</>
						)
					}
					{saveButtonReportsFlag &&
						<Button expand="block" disabled={submitting} loading={submitting && loadingType === 'SAVE'} onClick={() => saveReport()} >
							{t(saved ? 'reports.saved' : 'reports.saveReport')}
						</Button>
					}
				</IonCol>
			</IonRow>
			<Modal
				title={t('case.chooseRoomFloor')}
				size={ModalSize.MEDIUM}
				visible={showChooseFloorModal}
				close={() => setShowChooseFloorModal(false)}
				body={
					<div>
						<div className="space-y-2 mb-4">
							{floors.map((floor, idx) => (
								<label key={idx} className="flex items-center space-x-2">
									<input
										type="checkbox"
										checked={selectedFloors.includes(floor?.name ?? '')}
										onChange={e => {
											if (e.target.checked) {
												setSelectedFloors(prev => [...prev, floor?.name ?? '']);
											} else {
												setSelectedFloors(prev => prev.filter(floorName => floorName !== floor?.name));
											}
										}}
									/>
									<span>
										{floor.name}
									</span>
								</label>
							))}
						</div>

						<div>
							<Button
								id="PDF"
								expand="block"
								disabled={submitting}
								loading={submitting && reportFormat.current === ReportFormatType.PDF && loadingType === 'CREATE'}
								onClick={() => {
									submitReport(ReportFormatType.PDF);
									setSelectedFloors([]);
									setShowChooseFloorModal(false);
								}}
							>
								{t('reports.createPdfReport')}
							</Button>

							<Button
								id="WORD"
								expand="block"
								disabled={submitting}
								loading={submitting && reportFormat.current === ReportFormatType.WORD && loadingType === 'CREATE'}
								onClick={() => {
									submitReport(ReportFormatType.WORD);
									setSelectedFloors([]);
									setShowChooseFloorModal(false);
								}}
							>
								{t('reports.createWordReport')}
							</Button>
						</div>
					</div>
				}
			/>
		</BasePage>
	);
};

export default TrygWaterDamageReport;
