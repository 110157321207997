import { DateTime } from 'luxon';

interface DestructuredEntries {
	jobNo: string | null;
	jobTaskNo: string;
	hours: number;
	onCall: boolean;
	startTime: string;
	endTime: string;
    startDate: string;
}

interface DestructuredAddonLines {
	paymentSupplementCode: string | null;
	jobNo: string | null;
	jobTaskNo: string;
	hours: number;
	onCall: boolean;
	startTime: string;
	endTime: string;
}

interface OnCallHoursReturnType {
	hours: number;
	overtimeHours: number;
	eveningHours: number;
	nightHours: number;
	sundayHours: number;
}

export const countOnCallHours = (entries: DestructuredEntries[], addonLines: DestructuredAddonLines[], jobTaskNo: string, onCallHourDiff: number): OnCallHoursReturnType => {

    // '2100', Overtid 1
    // '2101', Overtid 2
    // '2105', Overtid (INTERN)
    // '8599', Overtid (INTERN)
    const overTimeCodes = ['2100', '2101', '2105', '8599'];

    // '2051', Aften
    // '2052', Aften (INTERN)
    // Time frame: 18 - 22
    // const eveningCodes = ['2051', '2052'];

    // '2054', Nat
    // '2055', Nat (INTERN)
    // Time frame: 22 - 23:59 and 00 - 05
    // const nightCodes = ['2054', '2055'];

    // '2056', Sunday
    // '2057', Sunday (INTERN)
    // day 6
    const sundayCodes = ['2056', '2057'];

    let spilloverEveningHours = 0;
    let spilloverNightHours = 0;
    let sundayHours = 0;

    const hours = entries.reduce((acc, curr) => {
        if (curr.jobTaskNo === jobTaskNo && curr.onCall) {
            const difference = onCallHourDiff - curr.hours;

            // Calculate the end time by adding the difference
            const startDate = DateTime.fromISO(curr.startDate);
            const endTime = DateTime.fromFormat(curr.endTime, 'HH:mm');
            const onCallStartTime = DateTime.fromFormat(curr.endTime, 'HH:mm');
            const onCallEndTime = DateTime.fromFormat(curr.endTime, 'HH:mm').plus({ hours: difference });

            // Time boundaries
            const eveningStartRule = DateTime.fromFormat('18:00', 'HH:mm');
            const eveningEndRule = DateTime.fromFormat('22:00', 'HH:mm');
            const nightStartRule = DateTime.fromFormat('22:00', 'HH:mm');
            const nightEndRule = DateTime.fromFormat('05:00', 'HH:mm').plus({ days: 1 });

            // Case 1: Check if the end time is a Sunday
            const dayOfWeek = startDate.weekday;
            if (dayOfWeek === 7 || (dayOfWeek === 6 && onCallEndTime.hour > 0 && onCallEndTime.hour <= 5)) { // 7 represents Sunday, 6 represents Saturday
                console.log('Case 1: Sunday');
                sundayHours += difference;
            }

            // Case 2: If the onCallEndTime is before eveningStart (18:00)
            if (onCallEndTime <= eveningStartRule && onCallEndTime >= eveningEndRule) {
                console.log('Case 2: Before evening');
                const eveningStartTime = DateTime.max(endTime, eveningStartRule);
                const eveningHours = Math.min(onCallEndTime.diff(eveningStartTime, ['hours', 'minutes']).hours + (onCallEndTime.diff(eveningStartTime, ['hours', 'minutes']).minutes / 60), difference);
                
                spilloverEveningHours += Math.max(0, eveningHours);
                
                return acc += difference;
            }

            // Case 3: Spillover into the evening period (18:00–22:00)
            if (onCallEndTime >= eveningStartRule && onCallEndTime < eveningEndRule) {
                console.log('Case 3: Evening');
                const eveningStartTime = DateTime.max(endTime, eveningStartRule);
                const nightStartTime = DateTime.min(endTime, nightEndRule);
                const eveningHours = Math.min(onCallEndTime.diff(eveningStartTime, ['hours', 'minutes']).hours + (onCallEndTime.diff(eveningStartTime, ['hours', 'minutes']).minutes / 60), difference);
                // const nightHours = Math.min(onCallEndTime.diff(nightStartTime, ['hours', 'minutes']).hours + (onCallEndTime.diff(nightStartTime, ['hours', 'minutes']).minutes / 60), difference);
                
                spilloverEveningHours += Math.max(0, eveningHours);
                //difference = curr.hours;

            } else if (onCallEndTime > nightStartRule && onCallEndTime <= nightEndRule) {
                console.log('Case 4: Night');
                const nightStartTime = DateTime.max(endTime, nightStartRule);
                const nightHoursDiff = Math.min(onCallEndTime.diff(nightStartTime, ['hours', 'minutes']).hours + (onCallEndTime.diff(nightStartTime, ['hours', 'minutes']).minutes / 60), difference);

                // Find diff between onCallStartTime and nightStartTime
                const eveningHoursDiff = nightStartTime.diff(onCallStartTime, ['hours', 'minutes']).hours + (nightStartTime.diff(onCallStartTime, ['hours', 'minutes']).minutes / 60);
                
                spilloverEveningHours += eveningHoursDiff;
                spilloverNightHours += nightHoursDiff;
                //difference = curr.hours;

            } else if (onCallEndTime < nightEndRule) {
                console.log('Case 5: Night next day until 5');
                const nightStartTime = DateTime.min(endTime, nightEndRule);
                const nightHoursDiff = nightEndRule.diff(nightStartTime.plus({ days: 1}), 'hours').hours; // + (nightStartTime.diff(nightEndRule, 'minutes').minutes / 60);
                
                spilloverNightHours += nightHoursDiff;
                return acc + nightHoursDiff;
            }

            return acc + difference;
        }

        return acc;
    }, 0);
    

    const calcOvertimeHours = addonLines.reduce((acc, curr) => {
        if (curr.jobTaskNo === jobTaskNo && curr.onCall && (overTimeCodes.includes(curr.paymentSupplementCode ?? '') || sundayCodes.includes(curr.paymentSupplementCode ?? ''))) {
            const difference = onCallHourDiff - curr.hours;
            return acc + difference;
        }

        return acc;
    }, 0);

    // const calcEveningHours = addonLines.reduce((acc, curr) => {
    //     if (curr.jobTaskNo === jobTaskNo && curr.onCall && eveningCodes.includes(curr.paymentSupplementCode ?? '')) {
    //         const difference = onCallHourDiff - curr.hours;

    //         // Calculate the end time by adding the difference
    //         const endTime = DateTime.fromFormat(curr.endTime, 'HH:mm');
    //         const onCallEndTime = endTime.plus({ hours: difference });
    //         const eveningEnd = DateTime.fromFormat('22:00', 'HH:mm');

    //         let actualEveningHours = difference;

    //         // Check if the end time spills over into the night time frame
    //         if (onCallEndTime > eveningEnd) {
    //             const spillover = onCallEndTime.diff(eveningEnd, ['hours', 'minutes']).hours + (onCallEndTime.diff(eveningEnd, ['hours', 'minutes']).minutes / 60);

    //             actualEveningHours = Math.max(0, difference - spillover);

    //             spilloverNightHours += spillover;
    //         }

    //         return acc + actualEveningHours;
    //     }

    //     return acc;
    // }, 0);

    // const calcNightHours = addonLines.reduce((acc, curr) => {
	// 	if (curr.jobTaskNo === jobTaskNo && curr.onCall && nightCodes.includes(curr.paymentSupplementCode ?? '')) {
	// 		const difference = onCallHourDiff - curr.hours;

	// 		// Calculate the end time by adding the difference
    //         //const startTime = DateTime.fromFormat(curr.startTime, 'HH:mm');
	// 		const endTime = DateTime.fromFormat(curr.endTime, 'HH:mm');
	// 		const onCallEndTime = endTime.plus({ hours: difference });
    //         //const nightStartNextDay = DateTime.fromFormat('00:00', 'HH:mm');
	// 		const nightEnd = DateTime.fromFormat('05:00', 'HH:mm');

	// 		const actualNightHours = difference;

	// 		// Check if the end time spills over into the night time end
	// 		// if (onCallEndTime > nightEnd) {
	// 		// 	const spillover = onCallEndTime.diff(nightEnd, ['hours', 'minutes']).hours + (onCallEndTime.diff(nightEnd, ['hours', 'minutes']).minutes / 60);

	// 		// 	actualNightHours = Math.max(0, difference - spillover);
	// 		// }

	// 		return acc + actualNightHours;
	// 	}

	// 	return acc;
	// }, 0);

    // const calcSundayHours = addonLines.reduce((acc, curr) => {
    //     if (curr.jobTaskNo === jobTaskNo && curr.onCall && sundayCodes.includes(curr.paymentSupplementCode ?? '')) {
    //         const difference = onCallHourDiff - curr.hours;
    //         return acc + difference;
    //     }

    //     return acc;
    // }, 0);

    return {
        hours: hours,
        overtimeHours: calcOvertimeHours,
        eveningHours: spilloverEveningHours,
        nightHours: spilloverNightHours,
        sundayHours: sundayHours,
    };
};