import React from 'react';
import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { filesToInput, fileToInput } from '@ssg/common/Helpers/fileTypeHelper';
import { useHistory } from 'react-router-dom';
import { useStorageState } from '@ssg/common/Hooks/useLocalStorage';
import { removeTypenameGeneric } from '@ssg/common/Helpers/typenameHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { updateReportsCache, updateReportsCacheNew } from './updateReportsCache';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { toggleArrayValue } from '@ssg/common/Helpers/toggleArrayValue';
import { ESDHFileFragment, FloorMoistureReportFormDataInput, GetMobileV2DrivingSlip_drivingSlip_case, MoistureReportObservationsInput, ReportFileInput, ReportFormatType } from 'GQL';
import Button from 'Components/Button';
import BasePage from 'Components/Layout/BasePage';
import DrivingSlipSingleImageSelect from './Inputs/DrivingSlipSingleImageSelect';
import TextAreaInput from './Inputs/TextAreaInput';
import ReportStaticInfo from './ReportStaticInfo';
import TextButton from '@ssg/common/Components/TextButton';
import TextInput from './Inputs/TextInput';
import DrivingSlipMultiImageSelect from './Inputs/DrivingSlipMultiImageSelect';
import MoistureReportObservationsFrom from './Inputs/MoistureReportObservationsFrom';
import FormErrorText from '@ssg/common/Components/FormErrorText';
import MoistureMeassurementForm from './Inputs/MoistureMeassurementForm';
import useDrivingSlipImages from './useDrivingSlipImages';
import { checkFilesExist, tryGetFile } from './checkFilesExist';
import { useFlag } from '@unleash/proxy-client-react';
import { formatDateForInput } from '@ssg/common/Helpers/dateToDateOnlyString';
import Datepicker from '@ssg/common/Components/Datepicker';
import { FeatureFlagEnums } from '@ssg/common/FeatureFlagEnums';
import { ApiMutationKey, useCreateMoistureReport } from 'Store';
import dateToReportNameString from 'Helpers/dateHelper';
import { isConnected } from 'NetworkContext';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';

interface ReportError {
	key: keyof FloorMoistureReportFormDataInput;
	index: number;
}

interface Props {
	drivingSlipId: string;
	drivingSlipCase: GetMobileV2DrivingSlip_drivingSlip_case;
}

const EMPTY_OBSERVATIONS = (): MoistureReportObservationsInput => ({
	recommendations: '',
	roomOrApartment: '',
	scope: '',
	ceiling: '',
	construction: '',
	floor: '',
	walls: '',
});
const EMPTY_ROW = (): FloorMoistureReportFormDataInput => ({
	name: '',
	observations: EMPTY_OBSERVATIONS(),
	floorplanImage: tempFacadeImage,
	moistureMeassurements: [],
	otherImages: [],
});

const tempFacadeImage: ReportFileInput = {
	fileName: '',
	description: '',
};

const WaterDamageReport: React.FC<Props> = ({ drivingSlipId, drivingSlipCase }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const mobileFacadeImageSelectFlag = useFlag(FeatureFlagEnums.MOBILE_FACADE_IMAGE_SELECT);
	const reportFormat = React.useRef<ReportFormatType>(ReportFormatType.PDF);
	const saveButtonReportsFlag = useFlag(FeatureFlagEnums.SAVE_BUTTON_REPORTS);
	const optionalRoomFloorInReports = useFlag(FeatureFlagEnums.OPTIONAL_ROOM_FLOOR_IN_REPORTS);

	const { images } = useDrivingSlipImages(drivingSlipCase.erpNo, drivingSlipId);

	const useLocalStorage = <T,>(name: string, initialValue: T) => useStorageState<T>(window.localStorage, `${drivingSlipCase.id}-water-${name}`, initialValue);

	// Form values
	const [facadeImage, setFacadeImage] = useLocalStorage<ESDHFileFragment | null>('facadeImage', null);
	const [inspectionDate, setInspectionDate] = useLocalStorage('inspectionDate-indoorClimate', formatDateForInput(new Date()));
	const [conclusion, setConclusion] = useLocalStorage('conclusion', '');
	const [contacts, setContacts] = useLocalStorage('contacts', '');
	const [damageCause, setDamageCause] = useLocalStorage('damageCause', '');
	const [scope, setScope] = useLocalStorage('scope', '');
	//const [floorplanIndexedImage, setFloorplanIndexedImage] = React.useState<Map<number, ESDHFileFragment | null>>(new Map<number, null>());
	const [damageCauseImages, setDamageCauseImages] = useLocalStorage<ESDHFileFragment[]>('damageCauseImages', []);
	//const [otherIndexedImages, setOtherIndexedImages] = React.useState<Map<number, ESDHFileFragment[]>>(new Map<number, ESDHFileFragment[]>());
	const [activeTab, setActiveTab] = React.useState<number>(0);
	const [reportErrors, setReportErrors] = React.useState<ReportError[]>([]);
	const [floors, setFloors] = useLocalStorage<FloorMoistureReportFormDataInput[]>('floorReport', [EMPTY_ROW()]);

	// Disgusting fix
	const isSetRef = React.useRef(false);
	React.useEffect(() => {
		const formData = removeTypenameGeneric(drivingSlipCase.caseReports?.moistureReportFormData);
		if (formData && !isSetRef.current) {
			if (formData.inspectionDate) setInspectionDate(formatDateForInput(new Date(formData.inspectionDate)));
			if (formData.conclusion) setConclusion(formData.conclusion);
			if (formData.contacts) setContacts(formData.contacts);
			if (formData.damageCause) setDamageCause(formData.damageCause);
			if (formData.scope) setScope(formData.scope);

			if (formData.facadeImage) {
				const sanitizedFacadeImage = checkFilesExist([formData.facadeImage], images)[0];
				if (sanitizedFacadeImage) {
					setFacadeImage(tryGetFile(sanitizedFacadeImage.fileName, images));
				}
			}

			if (formData.floorReport) {
				const floorsData = removeTypenameGeneric(formData.floorReport);
				const sanitizedFloorsData = floorsData.map(f => {
					return {
						...f,
						otherImages: checkFilesExist(f.otherImages ?? [], images),
						floorplanImage: checkFilesExist(f.floorplanImage ? [f.floorplanImage] : [], images)[0],
					};
				});
				if (sanitizedFloorsData) setFloors(sanitizedFloorsData);
			}

			if (formData.damageCauseImages && formData.damageCauseImages.length > 0) {
				const sanitizedDamageImages = checkFilesExist(formData.damageCauseImages, images);

				const damageImages = sanitizedDamageImages.reduce((images: ESDHFileFragment[], otherImage) => {
					const file = tryGetFile(otherImage.fileName, images);
					if (file) {
						images.push(file);
					}
					return images;
				}, []);

				if (damageImages) {
					setDamageCauseImages(damageImages);
				}
			}
		}
		isSetRef.current = true;
	}, [drivingSlipCase.caseReports?.moistureReportFormData, images, setConclusion, setContacts, setDamageCause, setFacadeImage, setFloors, setInspectionDate, setScope]);

	const [submitted, setSubmitted] = React.useState(false);
	const [saved, setSaved] = React.useState(false);
	const [loadingType, setLoadingType] = React.useState<'CREATE' | 'SAVE'>('CREATE');
	const [facadeImageError, setFacadeImageError] = React.useState(false);
	const [damageCauseError, setDamageCauseError] = React.useState(false);
	const [scopeError, setScopeError] = React.useState(false);
	const [contactsError, setContactsError] = React.useState(false);

	const [showChooseFloorModal, setShowChooseFloorModal] = React.useState(false);
	const [selectedFloors, setSelectedFloors] = React.useState<string[]>([]);

	// Setup mutation to create water damage report
	const [createMoistureReport, { loading: submitting }] = useCreateMoistureReport();

	const handleChange = (idx: number, column: keyof FloorMoistureReportFormDataInput, value: any) =>
		setFloors(current => {
			let originalValue = value;
			if (column === 'floorplanImage') {
				//const flrPlanImages = floorplanIndexedImage;
				//flrPlanImages?.set(idx, value);
				//setFloorplanIndexedImage(flrPlanImages);
				originalValue = fileToInput(value as ESDHFileFragment);
			}

			if (column === 'otherImages') {
				// const otherImages = otherIndexedImages;
				// otherImages.set(idx, value as ESDHFileFragment[]);
				// setOtherIndexedImages(otherImages);
				const fileValue = value as ESDHFileFragment;
				const imageArrToggles = toggleArrayValue(current[idx].otherImages || [], { fileName: fileValue.name }, img => img.fileName);

				originalValue = imageArrToggles;
			}

			return [
				...current.slice(0, idx),
				{
					...current[idx],
					[column]: originalValue,
				},
				...current.slice(idx + 1),
			];
		});

	function addRow() {
		setFloors(current => [...current, EMPTY_ROW()]);
		setActiveTab(floors.length);
	}

	function removeRow(idx: number) {
		// setFloorplanIndexedImage(current => {
		//     current.delete(idx);
		//     return current;
		// });
		// setOtherIndexedImages(current => {
		//     current.delete(idx);
		//     return current;
		// });
		if (window.confirm(t('reports.confirmRemoveFloor'))) {
			setFloors(current => current.filter((_, i) => i !== idx));
			setActiveTab(0);
		}
	}

	//React.useEffect(() => console.log(floorplanIndexedImage),[floorplanIndexedImage]);

	const getDescription = (desciptionMap: ReportFileInput[], fileName: string) => {
		return desciptionMap.find(dm => dm.fileName === fileName);
	};

	async function submitReport(reportFormatType: ReportFormatType) {
		reportFormat.current = reportFormatType;

		setSubmitted(true);
		const errors = errorCheck(floors);

		setReportErrors(errors);
		setFacadeImageError(facadeImage == null);
		setDamageCauseError(damageCause.length < 1);
		setScopeError(scope.length < 1);
		setContactsError(contacts.length < 1);

		if (floors.length === 0 || facadeImage == null || errors.length > 0 || damageCause.length < 1 || scope.length < 1 || contacts.length < 1) {
			return;
		}

		const descriptionMap: ReportFileInput[] = images.map(image => ({
			fileName: image.name,
			description: image.comments ?? '',
		}));
		const thisFacadeImage = images.find(image => image.name === facadeImage.name) ?? facadeImage;
		const thisDamageCauseImages = damageCauseImages?.map(oi => images.find(image => image.name === oi.name) ?? oi);

		const theseFloors = floors.slice();
		theseFloors.forEach((floor, i) => {
			theseFloors[i].floorplanImage = getDescription(descriptionMap, theseFloors[i].floorplanImage?.fileName ?? '') ?? theseFloors[i].floorplanImage;
			theseFloors[i].otherImages = floor.otherImages?.map(oi => getDescription(descriptionMap, oi.fileName) ?? oi);
		});

		try {
			setLoadingType('CREATE');
			await createMoistureReport({
				variables: {
					caseId: drivingSlipCase.id,
					formData: {
						inspectionDate: formatDateForInput(new Date(inspectionDate)),
						conclusion,
						facadeImage: fileToInput(thisFacadeImage),
						contacts,
						damageCause,
						scope,
						damageCauseImages: filesToInput(thisDamageCauseImages),
						floorMoistureReportFormDataInput: theseFloors,
					},
					onlySave: false,
					floorsToInclude: selectedFloors.length > 0 ? selectedFloors : null,
					reportFormat: reportFormat.current,
				},
				onOptimisticResponseOverride: cache => {
					const created = new Date().toISOString();
					const createdSplit = dateToReportNameString(created);
					const extension = reportFormat.current === ReportFormatType.PDF ? 'pdf' : 'docx';
					const report = {
						name: `Fugtrapport_${drivingSlipCase.erpNo}_${createdSplit}.${extension} (Oprettet Offline, kan ikke tilgås endnu)`,
						comments: null,
						created: created,
						extension: extension,
						fileId: created,
						groupingKey: '',
						metadata: [{ key: 'DokumentType', value: 'Rapport' }],
						thumbnail: '',
						url: 'report.' + extension,
						__typename: 'ESDHFile',
					};
					if (!isConnected()) {
						updateReportsCacheNew(drivingSlipCase.erpNo, report, cache);
					}
					// const cachedRequest = cache.readQuery<GetCaseFiles, GetCaseFilesVariables>({
					// 	query: GET_CASE_FILES,
					// 	variables: {
					// 		caseErpNo: drivingSlipCase.erpNo,
					// 		folder: drivingSlipCase.erpNo,
					// 	},
					// });
					// console.log(cachedRequest);
					// console.log([report, ...(cachedRequest?.caseFiles ?? [])]);
					// cache.writeQuery<GetCaseFiles, GetCaseFilesVariables>({
					// 	query: GET_CASE_FILES,
					// 	variables: {
					// 		caseErpNo: drivingSlipCase.erpNo,
					// 		folder: drivingSlipCase.erpNo,
					// 	},
					// 	data: {
					// 		caseFiles: [report, ...(cachedRequest?.caseFiles ?? [])],
					// 	},
					// });
				},
				update: updateReportsCache(drivingSlipCase.erpNo, result => result.createMoistureReport),
				queueOfflineMutationOptions: {
					key: ApiMutationKey.DrivingSlip_CreateMoistureReport,
					removePreviousEntry: true,
					findPreviousEntryPredicate(variables) {
						return variables.caseId === drivingSlipCase.id;
					},
				},
			});

			history.goBack();
		} catch (e) {
			console.error(e);
		}
	}
	React.useEffect(() => {
		setSaved(false);
	}, [inspectionDate,
		conclusion,
		facadeImage,
		contacts,
		damageCause,
		scope,
		damageCauseImages,
		floors]);

	async function saveReport() {
		const descriptionMap: ReportFileInput[] = images.map(image => ({
			fileName: image.name,
			description: image.comments,
		}));
		const thisFacadeImage = images.find(image => image.name === facadeImage?.name) ?? facadeImage;
		const thisDamageCauseImages = damageCauseImages?.map(oi => images.find(image => image.name === oi.name) ?? oi);

		const theseFloors = floors.slice();
		theseFloors.forEach((floor, i) => {
			theseFloors[i].floorplanImage = getDescription(descriptionMap, theseFloors[i].floorplanImage?.fileName ?? '') ?? theseFloors[i].floorplanImage;
			theseFloors[i].otherImages = floor.otherImages?.map(oi => getDescription(descriptionMap, oi.fileName) ?? oi);
		});

		try {
			setLoadingType('SAVE');
			await createMoistureReport({
				variables: {
					caseId: drivingSlipCase.id,
					formData: {
						inspectionDate: formatDateForInput(new Date(inspectionDate)),
						conclusion,
						facadeImage: thisFacadeImage ? fileToInput(thisFacadeImage) : null,
						contacts,
						damageCause,
						scope,
						damageCauseImages: filesToInput(thisDamageCauseImages),
						floorMoistureReportFormDataInput: theseFloors,
					},
					reportFormat: reportFormat.current,
					onlySave: true,
				},
				queueOfflineMutationOptions: {
					key: ApiMutationKey.DrivingSlip_CreateMoistureReport,
					removePreviousEntry: true,
					findPreviousEntryPredicate(variables) {
						return variables.caseId === drivingSlipCase.id;
					},
				},
			});
			setSaved(true);
		} catch (e) {
			console.error(e);
		}
	}
	const resetForm = () => {
		setConclusion('');
		setFacadeImage(null);
		setConclusion('');
		setContacts('');
		setDamageCause('');
		setScope('');
		setDamageCauseImages([]);
		setFloors([EMPTY_ROW()]);
	};

	const errorCheck = (flrs: FloorMoistureReportFormDataInput[]) => {
		const errors: ReportError[] = [];
		flrs.forEach((f, i) => {
			if (f.name.length < 1) {
				errors.push({ index: i, key: 'name' });
			}
			// if(f.damageCause.length < 1) {
			//     errors.push({index: i, key: 'damageCause'});
			// }
			// if(f.scope.length < 1) {
			//     errors.push({index: i, key: 'scope'});
			// }
			// if(f.contacts.length < 1) {
			//     errors.push({index: i, key: 'contacts'});
			// }
			if (!f.floorplanImage || f.floorplanImage?.fileName.length < 1) {
				errors.push({ index: i, key: 'floorplanImage' });
			}
		});

		return errors;
	};

	const throwErrorMessage = (index: number, key: keyof FloorMoistureReportFormDataInput): string | undefined => {
		const isError = reportErrors.filter(re => re.index === index && re.key === key).length > 0;
		if (isError) return 'error.required';
		return;
	};

	React.useEffect(() => {
		if (submitted) {
			setFacadeImageError(facadeImage == null);
			setDamageCauseError(damageCause.length < 1);
			setScopeError(scope.length < 1);
			setContactsError(contacts.length < 1);
			const errors = errorCheck(floors);
			setReportErrors(errors);
		}
	}, [contacts.length, damageCause.length, facadeImage, floors, scope.length, submitted]);

	// if (caseReportsAndDocumentsLoading) {
	// 	return (
	// 		<BasePage title={t('reports.reportTypes.water')}>
	// 			<>
	// 				<IonSkeletonText animated className="w-full pt-5" />
	// 				<IonSkeletonText animated className="w-full pt-5" />
	// 			</>
	// 		</BasePage>
	// 	);
	// }

	React.useEffect(() => {
		setSelectedFloors(floors.map(f => f.name).filter((name): name is string => name !== undefined));
	}, [floors]);

	return (
		<BasePage title={t('reports.reportTypes.water')}>
			<IonGrid className="mb-4 px-0 text-sm">
				<IonRow>
					<ReportStaticInfo drivingSlipCase={drivingSlipCase} resetForm={() => resetForm()} />

					{mobileFacadeImageSelectFlag ? (
						<DrivingSlipMultiImageSelect
							label={t('reports.uploadFacadeImage')}
							caseNo={drivingSlipCase.erpNo}
							drivingSlipId={drivingSlipId}
							onImageSelect={setFacadeImage}
							selectedImages={facadeImage ? [facadeImage] : []}
						/>
					) : (
						<DrivingSlipSingleImageSelect
							caseNo={drivingSlipCase.erpNo}
							drivingSlipId={drivingSlipId}
							noImageText={t('reports.noFacadeImage')}
							uploadButtonText={t('reports.uploadFacadeImage')}
							onImageSelect={setFacadeImage}
							selectedImage={facadeImage}
						/>
					)}
					{facadeImageError && <FormErrorText text="reports.error.facadeImage" />}
					<Datepicker
						title="reports.inspectionDate"
						value={inspectionDate}
						onChange={e => setInspectionDate(e.target.value)}
						name="reports.inspectionDate"
						className="mx-1"
						titleClass="mx-1"
					/>
					<TextAreaInput
						label={t('reports.damageCause')}
						placeholder={t('reports.damageCausePlaceholder')}
						value={damageCause}
						onChange={setDamageCause}
						required={true}
						errorMessage={damageCauseError ? 'common.required' : undefined}
					/>

					<TextAreaInput
						label={t('reports.scope')}
						placeholder={t('reports.scopePlaceholder')}
						value={scope}
						//onChange={setScope}
						onChange={setScope}
						errorMessage={scopeError ? 'common.required' : undefined}
						required
					/>

					<TextAreaInput
						label={t('reports.contacts')}
						placeholder={t('reports.contactsPlaceholder')}
						value={contacts}
						onChange={setContacts}
						errorMessage={contactsError ? 'common.required' : undefined}
						required
					/>

					<DrivingSlipMultiImageSelect
						label={t('reports.damageCauseImages')}
						caseNo={drivingSlipCase.erpNo}
						drivingSlipId={drivingSlipId}
						onImageSelect={image => setDamageCauseImages(current => toggleArrayValue(current, image, img => img.url))}
						selectedImages={damageCauseImages}
					/>
					<div className="flex flex-wrap">
						<div className="w-full">
							<ul className="mb-0 flex list-none flex-row flex-wrap space-y-2 pt-3 pb-4" role="tablist">
								{floors.map((floor, idx) => (
									<li key={idx} className="mr-2 -mb-px w-full flex-auto text-center last:mr-0 sm:w-auto">
										{idx === 0}{' '}
										<a
											className={
												'block rounded px-5 py-3 text-xs font-bold uppercase leading-normal shadow-lg ' +
												(activeTab === idx ? 'bg-blue-calm text-white' : 'text-blue-calm bg-white')
											}
											onClick={e => {
												e.preventDefault();
												setActiveTab(idx);
											}}
											data-toggle="tab"
											href={'#link' + idx}
											role="tablist"
										>
											{typeof reportErrors.find(re => re.index === idx) !== 'undefined' && <FontAwesomeIcon icon={faExclamationTriangle} className="text-red mr-1" size="lg" />}{' '}
											{t('reports.floor')} - {floor.name}
										</a>
									</li>
								))}

								<li className="mr-2 -mb-px flex-auto text-center last:mr-0">
									<Button onClick={addRow}>{t('reports.addFloor')}</Button>
								</li>
							</ul>
							<div className="relative mb-6 flex w-full min-w-0 flex-col break-words rounded bg-white shadow-lg">
								<div className="flex-auto py-5">
									<div className="tab-content tab-space">
										{floors.map((floor, idx) => (
											<div key={'#link' + idx} className={activeTab === idx ? 'block' : 'hidden'} id={'#link' + idx}>
												<IonGrid className="mb-4 text-sm">
													{idx > 0 && (
														<TextButton text="reports.removeFloor" className="ml-auto mr-0" icon={faTimes} iconClassName="text-red" onClick={() => removeRow(idx)} />
													)}
													<IonRow>
														<IonCol size="12">
															<IonLabel className="text-blue text-base font-semibold">{t('reports.moistureReportObservations.title')}</IonLabel>
														</IonCol>

														<TextInput
															label={t('reports.name')}
															placeholder={t('reports.name')}
															value={floor.name}
															onChange={value => handleChange(idx, 'name', value)}
															required={true}
															errorMessage={throwErrorMessage(idx, 'name')}
														/>

														<MoistureReportObservationsFrom
															observations={floor.observations}
															onChange={e => handleChange(idx, 'observations', e)}
														//onChange={setObservations}
														/>

														<MoistureMeassurementForm
															meassurements={floor.moistureMeassurements || []}
															onChange={e => handleChange(idx, 'moistureMeassurements', e)}
														//onChange={setMoistureMeassurements}
														/>

														<DrivingSlipSingleImageSelect
															caseNo={drivingSlipCase.erpNo}
															key={idx + '_noOfFloorPlanImage'}
															drivingSlipId={drivingSlipId}
															noImageText={t('reports.noFloorplanImage')}
															uploadButtonText={t('reports.uploadFloorplanImage')}
															onImageSelect={e => handleChange(idx, 'floorplanImage', e)}
															selectedImage={images.filter(img => img.name === floor.floorplanImage?.fileName)[0] ?? undefined}
														/>
														{typeof throwErrorMessage(idx, 'floorplanImage') !== 'undefined' && <FormErrorText text="reports.error.floorplanImage" />}

														<DrivingSlipMultiImageSelect
															label={t('reports.images')}
															caseNo={drivingSlipCase.erpNo}
															drivingSlipId={drivingSlipId}
															onImageSelect={image => handleChange(idx, 'otherImages', image)}
															selectedImages={images.filter(img => (floor.otherImages || []).map(oi => oi.fileName).includes(img.name))}
														/>
													</IonRow>

													{idx > 0 && (
														<p className="text-red pt-2 text-right text-xl font-bold" onClick={() => removeRow(idx)}>
															x <span className="text-sm">Remove Floor</span>
														</p>
													)}
												</IonGrid>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>

					<br></br>
					<TextAreaInput label={t('reports.conclusion')} placeholder={t('reports.conclusionPlaceholder')} value={conclusion} onChange={setConclusion} />
					<IonCol size="12">
						{optionalRoomFloorInReports
							? <Button expand="block" onClick={() => setShowChooseFloorModal(true)}>Generer rapport</Button>
							: (
								<>
							<Button id="PDF" expand="block" disabled={submitting} loading={submitting && reportFormat.current === ReportFormatType.PDF && loadingType === 'CREATE'} onClick={() => submitReport(ReportFormatType.PDF)}>
								{t('reports.createPdfReport')}
							</Button>

							<Button id="WORD" expand="block" disabled={submitting} loading={submitting && reportFormat.current === ReportFormatType.WORD && loadingType === 'CREATE'} onClick={() => submitReport(ReportFormatType.WORD)}>
								{t('reports.createWordReport')}
							</Button>
							</>
							)
						}
						{saveButtonReportsFlag &&
							<Button expand="block" disabled={submitting} loading={submitting && loadingType === 'SAVE'} onClick={() => saveReport()} >
								{t(saved ? 'reports.saved' : 'reports.saveReport')}
							</Button>
						}
					</IonCol>
				</IonRow>
			</IonGrid>
			<Modal
				title={t('case.chooseRoomFloor')}
				size={ModalSize.MEDIUM}
				visible={showChooseFloorModal}
				close={() => setShowChooseFloorModal(false)}
				body={
					<div>
						<div className="space-y-2 mb-4">
							{floors.map((floor, idx) => (
								<label key={idx} className="flex items-center space-x-2">
									<input
										type="checkbox"
										checked={selectedFloors.includes(floor?.name ?? '')}
										onChange={e => {
											if (e.target.checked) {
												setSelectedFloors(prev => [...prev, floor?.name ?? '']);
											} else {
												setSelectedFloors(prev => prev.filter(floorName => floorName !== floor?.name));
											}
										}}
									/>
									<span>
										{floor.name}
									</span>
								</label>
							))}
						</div>

						<div>
							<Button
								id="PDF"
								expand="block"
								disabled={submitting}
								loading={submitting && reportFormat.current === ReportFormatType.PDF && loadingType === 'CREATE'}
								onClick={() => {
									submitReport(ReportFormatType.PDF);
									setSelectedFloors([]);
									setShowChooseFloorModal(false);
								}}
							>
								{t('reports.createPdfReport')}
							</Button>

							<Button
								id="WORD"
								expand="block"
								disabled={submitting}
								loading={submitting && reportFormat.current === ReportFormatType.WORD && loadingType === 'CREATE'}
								onClick={() => {
									submitReport(ReportFormatType.WORD);
									setSelectedFloors([]);
									setShowChooseFloorModal(false);
								}}
							>
								{t('reports.createWordReport')}
							</Button>
						</div>
					</div>
				}
			/>
		</BasePage>
	);
};

export default WaterDamageReport;
