import { IonInput, IonLabel, useIonModal } from '@ionic/react';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import {
	GetDrivingSlipItems,
	DeleteMobileV2ItemUsage,
	DeleteMobileV2ItemUsageVariables,
	GetMobileV2DrivingSlip_drivingSlip_case_caseDraftItemsUsage,
	V2DrivingSlipCaseFragment,
	GetMobileV2CaseAdminData_drivingSlipItems,
	CaseDraftUsageLineFragment,
} from 'GQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSpinner, faSquare, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import React, { useMemo } from 'react';
import Table from '@ssg/common/Components/Table';
import classNames from 'classnames';
import Button from 'Components/Button';
import CloseableModal from 'Components/CloseableModal';
import UserContext from 'UserContext';
import SearchableList from 'Components/SearchableList';
import { useOfflineHandlingMutation } from 'Hooks';
import { ApiMutationKey, useAppSelector, useCreateCaseDraftItemUsage } from 'Store';
import { isConnected } from 'NetworkContext';
import TextButton from '@ssg/common/Components/TextButton';

export interface Props {
	caseId: string;
	caseNo: string;
	drivingSlipCategory: string;
	itemUsage: GetMobileV2DrivingSlip_drivingSlip_case_caseDraftItemsUsage[];
	onDismiss: () => void;
}

export function removeDuplicates(arr: GetMobileV2CaseAdminData_drivingSlipItems[]) {
	const unique: GetMobileV2CaseAdminData_drivingSlipItems[] = [];
	arr.forEach(element => {
		if (unique.findIndex(u => u.no === element.no) === -1) {
			unique.push(element);
		}
	});
	return unique;
}

export function generateRandomItemUsageId(): string {
	const generateRandomSegment = () => {
		return Math.floor(Math.random() * 0x10000)
			.toString(16)
			.padStart(4, '0');
	};

	const idSegments = [
		generateRandomSegment() + generateRandomSegment(),
		generateRandomSegment(),
		generateRandomSegment(),
		generateRandomSegment(),
		generateRandomSegment() + generateRandomSegment() + generateRandomSegment(),
	];

	return idSegments.join('-');
}

const DELETE_ITEM_USAGE = loader('src/GQL/Cases/DeleteMobileV2ItemUsage.gql');
const V2_DRIVING_SLIP_CASE_FRAGMENT = loader('src/GQL/DrivingSlips/V2DrivingSlipCaseFragment.gql');

const ItemUsageModal: React.FC<Props> = ({ caseId, caseNo, drivingSlipCategory, onDismiss, itemUsage }) => {
	const { t } = useTranslation();

	const userContext = React.useContext(UserContext);

	const {
		caseAdminData: {
			data: { drivingSlipItems },
		},
	} = useAppSelector(state => state.apiData);

	const [createItemUsage, { loading: createItemUsageLoading }] = useCreateCaseDraftItemUsage();

	const [deleteItemUsage, { loading: deleteItemUsageLoading }] = useOfflineHandlingMutation<DeleteMobileV2ItemUsage, DeleteMobileV2ItemUsageVariables>(DELETE_ITEM_USAGE);

	const deletingItemErpIdRef = React.useRef<string>();
	const [shouldFilter, setShouldFilter] = React.useState(true);

	// TODO: What's the proper way to filter non-waste items?
	const itemsToDisplay = useMemo(
		() => removeDuplicates(drivingSlipItems.filter(x => !x.description.toLowerCase().includes('Affaldsafgift'.toLowerCase())).filter(x => (shouldFilter ? x.filterCode === drivingSlipCategory : true)) ?? []),
		[drivingSlipCategory, drivingSlipItems, shouldFilter],
	);

	const usageLines = React.useMemo(() => itemUsage.filter(x => drivingSlipItems.filter(x => !x.description.toLowerCase().includes('Affaldsafgift'.toLowerCase())).some(y => y.no === x.erpKey)) ?? [], [drivingSlipItems, itemUsage]);

	const [selectedItem, setSelectedItem] = React.useState<GetDrivingSlipItems['drivingSlipItems'][number]['no']>();
	const [quantity, setQuantity] = React.useState<number>(1);

	const handleDeleteItemUsage = async (erpId: string) => {
		deletingItemErpIdRef.current = erpId;

		await deleteItemUsage({
			variables: {
				itemERPReferenceNo: erpId,
				caseERPReferenceNo: caseNo,
			},
			// update: (cache, { data }) => {
			// 	if (typeof data === 'undefined' || data === null) {
			// 		return;
			// 	}

			// 	if (data.deleteItemUsage !== null) {
			// 		cache.writeQuery<GetCaseDraftUsageLines, GetCaseDraftUsageLinesVariables>({
			// 			query: GET_ITEM_USAGE,
			// 			variables: {
			// 				id: caseId,
			// 			},
			// 			data: {
			// 				case: {
			// 					id: caseId,
			// 					caseDraftItemsUsage: [...data.deleteItemUsage],
			// 				},
			// 			},
			// 		});
			// 	}
			// },
			onOptimisticResponseOverride(cache) {
				const oldCase = cache.readFragment<V2DrivingSlipCaseFragment>({
					id: 'Case:' + caseId,
					fragment: V2_DRIVING_SLIP_CASE_FRAGMENT,
					fragmentName: 'V2DrivingSlipCaseFragment',
				});
				if (oldCase) {
					const itemRemovedArr = oldCase.caseDraftItemsUsage.filter(iu => iu.erpId !== erpId);
					cache.writeFragment<V2DrivingSlipCaseFragment>({
						id: 'Case:' + caseId,
						fragment: V2_DRIVING_SLIP_CASE_FRAGMENT,
						fragmentName: 'V2DrivingSlipCaseFragment',
						data: {
							...oldCase,
							caseDraftItemsUsage: [...itemRemovedArr],
						},
					});
				}
			},
		});

		setSelectedItem(undefined);
		setQuantity(1);
	};

	const [presentPickItemModal, dismissPickItemModal] = useIonModal(PickItemModal, {
		itemsToDisplay: itemsToDisplay,
		onDissmisPickItems: () => dismissPickItemModal(),
		setSelectedItem: () => setSelectedItem,
		active: !shouldFilter,
		setActive: setShouldFilter,
		loading: false,
	} as PickItemModalProps);

	return (
		<CloseableModal title={t('case.itemUsage')} onDismiss={onDismiss}>
			<div>
				<div>
					<label className="text-blue font-semibold">{t('case.caseNo')}:</label>
					<p>{caseNo}</p>

					<div className="border-1 mt-2 border-gray-400 p-2" onClick={() => presentPickItemModal()}>
						<IonLabel>
							{typeof selectedItem === 'undefined'
								? 'Vælg materiale'
								: `${itemsToDisplay.find(i => i.no === selectedItem)?.description} (${itemsToDisplay.find(i => i.no === selectedItem)?.baseUOM})`}
						</IonLabel>
					</div>

					<div className="mt-4 flex flex-col">
						<label className="text-blue font-semibold">{t('common.quantity')}</label>

						<IonInput
							type="number"
							min="0.01"
							onIonChange={e => setQuantity(Number(e.detail.value))}
							value={quantity}
							className={classNames('border-1 px-2', {
								'border-gray-400': quantity > 0,
								'border-red': quantity <= 0,
							})}
						/>
					</div>

					<Button
						color="primary"
						expand="full"
						onClick={async () => {
							if (typeof selectedItem !== 'undefined') {
								const itemToCreate = itemsToDisplay.find(i => i.no === selectedItem);
								if (itemToCreate) {
									const newUsageLine: CaseDraftUsageLineFragment = {
										date: new Date().toISOString().slice(0, 'yyyy-mm-dd'.length),
										description: itemToCreate.description,
										erpId: generateRandomItemUsageId(),
										erpKey: itemToCreate.no,
										title: itemToCreate.description,
										quantity: quantity,
										unitOfMeasureCode: '',
										postedBy: (userContext.user?.id ?? '').toUpperCase(),
										__typename: 'CaseDraftUsageLine',
									};
									await createItemUsage({
										variables: {
											caseERPReferenceNo: caseNo,
											itemERPReferenceNo: selectedItem,
											itemDescription: itemToCreate.description,
											jobTaskERPReferenceNo: itemToCreate.jobTask,
											quantity,
											unitOfMeasureCode: '',
										},
										queueOfflineMutationOptions: {
											key: ApiMutationKey.Case_CreateCaseDraftItemUsage,
											removePreviousEntry: false,
										},
										update: (cache, { data }) => {
											if (typeof data === 'undefined' || data === null || data.createCaseDraftItemUsage === null) {
												return;
											}
											const oldCase = cache.readFragment<V2DrivingSlipCaseFragment>({
												id: 'Case:' + caseId,
												fragment: V2_DRIVING_SLIP_CASE_FRAGMENT,
												fragmentName: 'V2DrivingSlipCaseFragment',
											});

											if (oldCase) {
												cache.writeFragment<V2DrivingSlipCaseFragment>({
													id: 'Case:' + caseId,
													fragment: V2_DRIVING_SLIP_CASE_FRAGMENT,
													fragmentName: 'V2DrivingSlipCaseFragment',
													data: {
														...oldCase,
														caseDraftItemsUsage: [...data.createCaseDraftItemUsage],
													},
												});
											}
										},
										onOptimisticResponseOverride(cache) {
											const oldCase = cache.readFragment<V2DrivingSlipCaseFragment>({
												id: 'Case:' + caseId,
												fragment: V2_DRIVING_SLIP_CASE_FRAGMENT,
												fragmentName: 'V2DrivingSlipCaseFragment',
											});

											if (oldCase) {
												cache.writeFragment<V2DrivingSlipCaseFragment>({
													id: 'Case:' + caseId,
													fragment: V2_DRIVING_SLIP_CASE_FRAGMENT,
													fragmentName: 'V2DrivingSlipCaseFragment',
													data: {
														...oldCase,
														caseDraftItemsUsage: [...oldCase.caseDraftItemsUsage, newUsageLine],
													},
												});
											}
										},
									});
								}

								setSelectedItem(undefined);
								setQuantity(1);
							}
						}}
						className="mt-8"
						disabled={typeof selectedItem === 'undefined' || quantity <= 0 || createItemUsageLoading}
						loading={createItemUsageLoading}
					>
						{t('common.confirm')}
					</Button>
				</div>

				{usageLines.length > 0 && (
					<div>
						<Table
							data={usageLines}
							noDataFoundText={t('common.noDataFound')}
							keySelector={iu => iu.erpId}
							columns={[
								{
									label: '',
									selectFn: iu => {
										if (!userContext.user || iu.postedBy.toUpperCase() !== userContext.user.id.toUpperCase()) {
											// Only show delete button if the line is posted by the current user
											return <></>;
										}

										const isDeleting = deleteItemUsageLoading && deletingItemErpIdRef.current === iu.erpId;

										return (
											<TextButton
												icon={isDeleting ? faSpinner : faTrashAlt}
												iconClassName="text-red text-lg"
												loading={isDeleting}
												disabled={iu.erpId === iu.erpKey}
												onClick={async () => {
													await handleDeleteItemUsage(iu.erpId);
												}}
											/>
										);
									},
									align: 'LEFT',
									hideColumn: !isConnected(),
								},
								{
									label: 'case.item',
									selectFn: iu => iu.title,
								},
								{
									label: 'common.quantity',
									selectFn: iu => iu.quantity,
								},
								{
									label: 'common.date',
									selectFn: iu => {
										return (
											<>
												<p>{iu.date}</p>
												<p
													style={{
														fontSize: 'smaller',
													}}
												>
													{iu.postedBy}
												</p>
											</>
										);
									},
									align: 'RIGHT',
								},
							]}
						/>
					</div>
				)}

				<Button color="primary" expand="full" className="mt-10" onClick={() => onDismiss()}>
					{t('case.itemUsageClose')}
				</Button>
			</div>
		</CloseableModal>
	);
};

export default ItemUsageModal;

interface PickItemModalProps {
	itemsToDisplay: GetMobileV2CaseAdminData_drivingSlipItems[];
	onDissmisPickItems(): void;
	setSelectedItem(): React.Dispatch<React.SetStateAction<string | undefined>>;
	active: boolean;
	setActive: React.Dispatch<React.SetStateAction<boolean>>;
	loading: boolean;
}

export const PickItemModal: React.FC<PickItemModalProps> = ({ itemsToDisplay, onDissmisPickItems, setSelectedItem, active, setActive, loading }): React.ReactElement => {
	const { t } = useTranslation();
	return (
		<CloseableModal title={t('case.selectMaterial')} onDismiss={onDissmisPickItems}>
			<SearchableList
				items={itemsToDisplay}
				onSelectionCancel={() => onDissmisPickItems()}
				onSelectionChange={setSelectedItem()}
				listLoading={loading}
				buttons={
					<Button color="primary" onClick={() => setActive(current => !current)}>
						<div>
							<p className="pb-px">
								<FontAwesomeIcon icon={active ? faCheckSquare : faSquare} size="lg" />
							</p>
							{t('case.showAll')}
						</div>
					</Button>
				}
			/>
		</CloseableModal>
	);
};
